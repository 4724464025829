export default {
    CALCULATE_CYCLES: "CALCULATE_CYCLES",
    SET_CHRONOCARDAMOUNT: "SET_CHRONOCARDAMOUNT",
    SET_TIMETOFALLASLEEP: "SET_TIMETOFALLASLEEP",
    SET_SINGLECYCLETIME: "SET_SINGLECYCLETIME",
    ALERT_MESSAGE: "ALERT_MESSAGE",
    HIDE_MESSAGE: "HIDE_MESSAGE",
    DEFINE_CHRONOTYPE: "DEFINE_CHRONOTYPE",
    CLEAR_CHRONOTYPE: "CLEAR_CHRONOTYPE",
    SET_TIME_TO_GET_UP: "SET_TIME_TO_GET_UP",
    SET_NOTIFICATION_PERMISSION: "SET_NOTIFICATION_PERMISSION",
    SET_NOTIFICATION_KEY: "SET_NOTIFICATION_KEY"
}